.App {
  min-height: 100vh;
  min-width: 100%;
}

.App .Background {
  min-height: 100vh;
  min-width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
}

.App .Background .Band {
  width: 100%;
  height: calc(100vh / 6);
  position: relative;
  display: block;
  content: "";
}

.App .Background .Band.Red {
  background-color: #cb2026;
}
.App .Background .Band.Orange {
  background-color: #f26722;
}
.App .Background .Band.Yellow {
  background-color: #fecd06;
}
.App .Background .Band.Green {
  background-color: #36b54e;
}
.App .Background .Band.Blue {
  background-color: #2F67B1;
}
.App .Background .Band.Purple {
  background-color: #6535af;
}

.App .Main {
  position: relative;
  min-height: calc(100vh * (5 / 6));
  width: 97%;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.App .Main .Dawg img {
  position: absolute;
  height: 200px;
  rotate: -45deg;
  top: 9rem;
  right: -250px;
  z-index: 0;
}

.App .Main .Dawg img.Active {
  animation: appear 10s;
}

@keyframes appear {
  0% { right: -250px; }
  15% { right: -70px; }
  85% { right: -70px; }
  100% { right: -250px; }
}

.App .Main .Header {
  text-align: center;
}

.App .Main .Header .Logo {
  width: 80%;
  height: 71px;
  max-width: 500px;
  margin: 2rem auto;
}

.App .Main .Counter {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem auto;
}

.App .Main .Counter .Value {
  font-size: 5em;
  min-height: 72px;
  line-height: .9em;
  font-family: 'Segoe UI Regular';
  padding: inherit auto 0 auto;
}

.App .Main .Counter .Descriptor {
  font-size: 1.8em;
  font-family: 'Segoe UI Semilight';
}

.App .Main .Rules .Title {
  font-size: 2.5em;
  min-height: 54px;
  font-family: 'Segoe UI Bold';
  text-align: center;
  margin: 2rem auto;
}

.App .Main .Rules .List {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  list-style: none;

  width: 100%;
  max-width: 1280px;
  padding: 0;
  margin: 0 auto 2.5rem auto;
  border-radius: 0 0 12px 12px;

  display: grid;
  grid-template-columns: repeat(auto-fit, 305px);
  justify-content: center;
  grid-gap: 5px;
  align-items: center;
}

.App .Main .Rules .List .Item {
  background-color: #fff;
  width: 100%;
  height: 45px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 10px;
  display: flex;
  flex-wrap: nowrap;
  box-shadow: 0 5px 6px -1px rgba(51, 51, 51, 0.13);
}

.App .Main .Rules .List .Item .Check {
  display: none;
}

.App .Main .Rules .List .Item .Text {
  width: calc(100% - 50px);
  padding: 5px 10px;
  display: flex;
  align-items: center;
}

.App .Main .Contact {
  text-align: center;
  margin: 10px;
}

.App .Footer {
  height: calc((100vh / 6) - 1px);
  position: relative;
  width: 97%;
  margin: 0 auto;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5), inset 0 0 10px rgba(0,0,0,.08);
  border-top: 1px solid rgb(228, 228, 228);
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media screen and (min-width: 390px) {
  .App .Main .Rules .List {
    grid-template-columns: repeat(auto-fit, 355px);
  }
  .App .Main .Rules .List .Item .Check {
    background: #36b54e;
    color: #fff;
    display: block;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    width: 50px;
  }
}

@media screen and (min-width: 760px) {
  .App .Main {
    border-radius: 7px 7px 0 0;
  }
  .App .Main .Rules .List {
    grid-gap: 10px;
  }
  .App .Footer {
    border-radius: 0 0 7px 7px;
  }
}

@media screen and (min-width: 1440px) {
  .App .Main .Rules .List {
    grid-gap: 15px;
  }
}
