.Button {
  animation: bounce 5s infinite;
  background-color: #ca3e47;
  border: none;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.25), 0 -8px 0 #b43d3d inset;
  border-radius: 1rem;
  color: #fff;
  display: inline-block;
  font-weight: 800;
  padding: 12px 32px 20px 32px;
  text-decoration: none;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 99999;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.Button.Active {
  animation: holdDown 0.1s;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.25), 0 0 0 #b43d3d inset,
  0 0 5px 0 rgba(0, 0, 0, 0.3) inset;
  padding: 12px 32px 12px 32px;
  margin: 8px 0 0 0;
}

@keyframes bounce {
  0% {
    margin: 3px 0 0 0;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.25), 0 -5px 0 #b43d3d inset;
  }
  2% {
    margin: 0px 0 0 0;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.25), 0 -8px 0 #b43d3d inset;
  }
  4% {
    margin: 3px 0 0 0;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.25), 0 -5px 0 #b43d3d inset;
  }
  6% {
    margin: 0px 0 0 0;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.25), 0 -8px 0 #b43d3d inset;
  }

}

@keyframes godown {
  from {
    margin: 0px 0 0 0;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.25), 0 -8px 0 #b43d3d inset;
    padding: 12px 32px 20px 32px;
  }
  to {
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.25), 0 -5px 0 #b43d3d inset, 0 0 5px 0 rgba(0, 0, 0, 0.3) inset;
    margin: 3px 0 0 0;
    padding: 12px 32px 15px 32px;
  }
}

@keyframes holdDown {
  from {
    margin: 0px 0 0 0;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.25), 0 -8px 0 #b43d3d inset;
  }
  to {
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.25), 0 0 0 #b43d3d inset, 0 0 5px 0 rgba(0, 0, 0, 0.3) inset;
    margin: 8px 0 0 0;
  }
}

@media (hover :hover) {
  .Button:hover {
    animation: godown 0.1s;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.25), 0 -5px 0 #b43d3d inset,
    0 0 5px 0 rgba(0, 0, 0, 0.3) inset;
    padding: 12px 32px 15px 32px;
    margin: 3px 0 0 0;
  }

  .Button:hover.Active {
    animation: holdDown 0.1s;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.25), 0 0 0 #b43d3d inset,
    0 0 5px 0 rgba(0, 0, 0, 0.3) inset;
    padding: 12px 32px 12px 32px;
    margin: 8px 0 0 0;
  }

  @keyframes holdDown {
    from {
      box-shadow: 0 4px 14px rgba(0, 0, 0, 0.25), 0 -5px 0 #b43d3d inset, 0 0 5px 0 rgba(0, 0, 0, 0.3) inset;
      margin: 3px 0 0 0;
      padding: 12px 32px 15px 32px;
    }
    to {
      box-shadow: 0 4px 14px rgba(0, 0, 0, 0.25), 0 0 0 #b43d3d inset, 0 0 5px 0 rgba(0, 0, 0, 0.3) inset;
      margin: 8px 0 0 0;
    }
  }
}
